import React from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import CookiePolicy from '@components/CookiePolicy/CookiePolicy';

const CookiePolicyPage = ({ location }) => {
  return (
    <Layout location={location} map>
      <Seo title="Cookie Policy - Tiziano Bieller Sport" lang="it" />
      <CookiePolicy />
    </Layout>
  );
};

export default CookiePolicyPage;
