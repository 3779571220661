import React from 'react';

import * as styles from './CookiePolicy.module.css';

const CookiePolicy = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Cookie Policy</h1>

      <div className={styles.section}>
        Questa è la Cookie Policy di <strong>Tiziano Bieller Sport</strong>,
        accessibile da <strong>www.tizianobiellersport.com</strong>
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>Cosa sono i cookie</div>
        Come è pratica comune con quasi tutti i siti Web professionali questo
        sito utilizza i cookie, che sono piccoli file che vengono scaricati sul
        tuo computer, per migliorare la tua esperienza. Questa pagina descrive
        quali informazioni essi raccogliere, come lo utilizziamo e perché a
        volte abbiamo bisogno di memorizzare questi cookie. Condivideremo anche
        come impedire la memorizzazione di questi cookie tuttavia ciò potrebbe
        eseguire il downgrade o la "rottura" di alcuni elementi dei siti
        funzionalità.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>Come utilizziamo i cookie</div>
        Utilizziamo i cookie per una serie di motivi descritti di seguito.
        Purtroppo dentro nella maggior parte dei casi non ci sono opzioni
        standard del settore per disabilitare i cookie senza disabilitare
        completamente le funzionalità e le caratteristiche a cui si aggiungono
        questo sito. Si consiglia di lasciare su tutti i cookie se lo sei non
        sono sicuro se ne hai bisogno o meno nel caso in cui vengano utilizzati
        per fornire a servizio che utilizzi.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>Disabilitazione dei cookie</div>
        Puoi impedire l'impostazione dei cookie modificando le impostazioni sul
        tuo browser (consultare la guida del browser per come eseguire questa
        operazione). Fai attenzione a la disabilitazione dei cookie influirà
        sulla funzionalità di questo e di molti altri siti web che visiti. La
        disabilitazione dei cookie di solito comporta anche disabilitare alcune
        funzionalità e caratteristiche di questo sito. Perciò si consiglia di
        non disabilitare i cookie.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>I Cookies che impostiamo</div>
        Cookie delle preferenze del sito <br />
        Al fine di fornire una grande esperienza su questo sito forniamo la
        funzionalità per impostare le tue preferenze su come funziona questo
        sito e quando lo usi. Per ricordare le tue preferenze abbiamo bisogno di
        impostare i cookie in modo che queste informazioni possano essere
        richiamate ogni volta che interagisci con una pagina è influenzato dalle
        tue preferenze.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>Cookie di terze parti</div>
        In alcuni casi particolari utilizziamo anche cookie forniti da terzi di
        fiducia partiti. La sezione seguente descrive in dettaglio quali cookie
        di terze parti vengono utilizzati dall'utente potrebbe incontrare
        attraverso questo sito. <br />
        Questo sito utilizza Google Analytics che è uno dei più diffusi e
        soluzione di analisi affidabile sul Web per aiutarci a capire come
        utilizzi il sito e modi in cui possiamo migliorare la tua esperienza.
        Queste i cookie possono tenere traccia di cose come quanto tempo
        trascorri sul sito e il pagine che visiti in modo da poter continuare a
        produrre contenuti accattivanti.
        <br />
        Per maggiori informazioni sui cookie di Google Analytics, consultare il
        sito ufficiale Pagina di Google Analytics.
      </div>

      <div className={styles.section}>
        <div className={styles.sectionTitle}>Maggiori informazioni</div>
        Spero che questo abbia chiarito le cose per te e come era in precedenza
        menzionato se c'è qualcosa di cui non sei sicuro di aver bisogno o di
        solito non è più sicuro lasciare i cookie abilitati nel caso in cui
        interagisca con una delle funzionalità che utilizzi sul nostro sito.
        <br />
        Per informazioni più generali sui cookie, si prega di leggere l'articolo
        "Cookie". dal Generatore di norme sulla privacy.
      </div>

      <div className={styles.section}>
        Tuttavia, se stai ancora cercando ulteriori informazioni, puoi
        contattarci tramite uno dei nostri metodi di contatto preferiti: <br />
        <br />
        Email:{' '}
        <a href="mailto:info@tizianobiellersport.com">
          info@tizianobiellersport.com
        </a>
        <br />
        Telefono: <a href="tel:+393394329988">339.4329988</a>
      </div>
    </div>
  );
};

export default CookiePolicy;
